




import { Component, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import ButtonMixin from "@/mixins/ButtonMixin";
import ActionButton from "@/components/Button/ActionButton.vue";

@Component({
  components: { ActionButton },
})
export default class WalletButton extends mixins(ButtonMixin) {
  public name = "WalletButton";

  @Prop({ required: false })
  public tooltipLabel: any;
}
