

























































































import { Component, Prop, Vue } from "vue-property-decorator";
import FormCard from "@/components/FormCard.vue";
import AbortButton from "@/components/Button/AbortButton.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import FscCard from "@/components/Card/FscCard.vue";
import TimePicker from "@/components/TimePicker.vue";
import Validation from "@/components/Validation.vue";
import RemoveButton from "@/components/Button/RemoveButton.vue";
import Datepicker from "@/components/Datepicker.vue";
import BookButton from "@/components/Button/BookButton.vue";
import { TranslateResult } from "vue-i18n";
import FscInputNumber from "@/components/Field/FscInputNumber.vue";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import RecurringModal from "@/views/Scheduler/RecurringModal.vue";
import CopyButton from "@/components/Button/CopyButton.vue";
import ParticipantsTable from "@/views/Scheduler/ParticipantsTable.vue";
import InstructorsTable from "@/views/Scheduler/InstructorsTable.vue";

@Component({
  components: {
    RecurringModal,
    FscSimpleCard,
    FscInputNumber,
    BookButton,
    Datepicker,
    Validation,
    TimePicker,
    FscCard,
    SaveButton,
    AbortButton,
    FormCard,
    RemoveButton,
    CopyButton,
    ParticipantsTable,
    InstructorsTable,
  },
})
export default class TheoryExamDetails extends Vue {
  public name = "TheoryExamDetails";

  public date: string | null = null;
  public time: string | null = null;
  public duration: number | undefined = 0;
  public note = null;

  public activeTab = 0;

  @Prop()
  public theoryExam!: any;

  public get endTime(): any {
    //TODO
    return "";
  }
  public studentFields = [
    {
      key: "name",
      label: "",
      sortable: false,
    },
  ];

  public onAbort(): void {
    this.$emit("abort");
  }

  public get tabLabel(): any {
    return (index: any) => (index === 0 ? this.$t("calendar.theory_details_info_tab") : this.$t("calendar.theory_details_participants"));
  }

  public get isDisabled(): boolean {
    return true;
    // return this.hasNotWritePermission;
  }

  protected get getLabel(): TranslateResult {
    return this.$t("sidebar.theory_exams") || "";
  }

  public changeTab(index: number) {
    this.activeTab = index;
  }
}
