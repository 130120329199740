















































import { Vue, Component, Prop } from "vue-property-decorator";
import Datepicker from "@/components/Datepicker.vue";
import FscMultiselect from "@/components/FscMultiselect.vue";
import AbortButton from "@/components/Button/AbortButton.vue";

@Component({
  components: {
    Datepicker,
    FscMultiselect,
    AbortButton,
  },
})
export default class CostBearersPaymentDocumentsFilter extends Vue {
  public name = "CostBearersPaymentDocumentsFilter.vue";

  @Prop()
  public selectedCostBearerId!: any;

  public selectedDateFrom: any = null;

  public selectedDateTo: any = null;

  public selectedDocumentTypeIds: any = [];

  public documentTypeIdsOptions = [
    { id: 601, name: "ZB" },
    { id: 602, name: "AZR" },
    { id: 603, name: "EAR" },
    { id: 604, name: "Storno-Rechnung" },
    { id: 605, name: "Kostenträgerrechnung" },
  ];

  public localStorageSetPaymentDocumentsFilter(): void {
    const filter = {
      dateFrom: this.selectedDateFrom,
      dateTo: this.selectedDateTo,
      documentTypeIds: this.selectedDocumentTypeIds,
    };
    sessionStorage.setItem("paymentDocumentsFilter", JSON.stringify(filter));
  }

  public localStorageGetPaymentDocumentsFilter(filter: any): void {
    const savedPaymentDocumentsFilter = JSON.parse(filter);
    if (savedPaymentDocumentsFilter) {
      this.selectedDateFrom = savedPaymentDocumentsFilter.dateFrom;
      this.selectedDateTo = savedPaymentDocumentsFilter.dateTo;
      this.selectedDocumentTypeIds = savedPaymentDocumentsFilter.documentTypeIds;
    }
  }

  public mounted(): void {
    if (sessionStorage.getItem("paymentDocumentsFilter") !== null) {
      this.localStorageGetPaymentDocumentsFilter(sessionStorage.getItem("paymentDocumentsFilter"));
    }
  }

  public onAbortFilter(): any {
    this.selectedDateFrom = null;
    this.selectedDateTo = null;
    this.selectedDocumentTypeIds = [];
    const filter = {
      costBearerId: this.selectedCostBearerId,
      dateFrom: null,
      dateTo: null,
      documentTypeIds: null,
    };
    sessionStorage.removeItem("paymentDocumentsFilter");
    this.$emit("on-abort-filter", {
      filter: filter,
    });
  }

  public onFilter(): any {
    const documentTypeIds: any = [];
    if (this.selectedDocumentTypeIds) {
      this.selectedDocumentTypeIds.map((documentId: any) => {
        documentTypeIds.push(documentId.id);
      });
    }
    const filter = {
      costBearerId: this.selectedCostBearerId,
      dateFrom: this.selectedDateFrom,
      dateTo: this.selectedDateTo,
      documentTypeIds: documentTypeIds.length > 0 ? documentTypeIds : null,
    };
    this.localStorageSetPaymentDocumentsFilter();
    this.$emit("on-filter", {
      filter: filter,
    });
  }
}
