














































import { Vue, Component, Prop } from "vue-property-decorator";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import FscPageHeader from "@/components/FscPageHeader.vue";
import Table from "@/components/Table.vue";
import AuthImage from "@/directives/AuthImage";
import Avatar from "@/components/Avatars/Avatar.vue";

@Component({
  components: {
    FscSimpleCard,
    FscPageHeader,
    Table,
    Avatar,
  },
  directives: {
    AuthImage: new AuthImage(),
  },
})
export default class CostBearersAttendees extends Vue {
  public name = "CostBearersAttendees";

  public fields = [
    {
      key: "name",
      label: "",
      sortable: true,
    },
    {
      key: "education",
      label: this.$t("sidebar.training"),
      sortable: false,
    },
    {
      key: "branch",
      label: this.$t("sidebar.branch"),
      sortable: false,
    },
    {
      key: "balance",
      label: this.$t("general.actual_saldo"),
      sortable: false,
    },
  ];

  @Prop()
  public educationsByCostBearer: any;

  public key = 0;

  protected formatSaldo(item: any): string {
    let formattedSaldo: any = 0;
    if (item.balance && item.balance.saldo) {
      const saldoAll = item.balance.saldo.toString();
      const containsNegative = saldoAll.includes("-");
      if (containsNegative) {
        const negativeRemoved = saldoAll.slice(1);
        const positiveSaldo = negativeRemoved.split(".");
        const finalFormatSaldo = this.formattingSaldo(positiveSaldo);
        formattedSaldo = `- ${finalFormatSaldo}`;
      } else {
        const splittedSaldo = saldoAll.split(".");
        formattedSaldo = this.formattingSaldo(splittedSaldo);
      }
    }
    return `${formattedSaldo} €`;
  }

  public onClickedEducation(education: any): void {
    this.$emit("on-clicked-education", education);
  }

  public mounted(): void {
    this.$root.$on("refresh-table-cost-bearers-attendees", () => {
      this.key++;
    });
  }

  public formattingSaldo(splittedSaldo: any) {
    let formattedSaldo: any = 0;
    if (splittedSaldo.length > 1) {
      if (splittedSaldo[0].length > 3) {
        let point = ".";
        let position = splittedSaldo[0].length - 3;
        let positionedSaldo = [splittedSaldo[0].slice(0, position), point, splittedSaldo[0].slice(position)].join("");
        formattedSaldo = `${positionedSaldo}, ${splittedSaldo[1]}`;
      } else {
        formattedSaldo = `${splittedSaldo[0]},${splittedSaldo[1]}`;
      }
    } else {
      if (splittedSaldo[0].length > 3) {
        let point = ".";
        let position = splittedSaldo[0].length - 3;
        let positionedSaldo = [splittedSaldo[0].slice(0, position), point, splittedSaldo[0].slice(position)].join("");
        formattedSaldo = `${positionedSaldo}`;
      } else {
        formattedSaldo = `${splittedSaldo[0]}`;
      }
    }
    return formattedSaldo;
  }
}
