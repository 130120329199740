import { Component, Vue } from "vue-property-decorator";
import createCancelableRequest from "@/utils/axiosCancelable";
import { AxiosError, AxiosResponse } from "axios";

@Component
export default class TodoRequestMixin extends Vue {
  private todoCreateRequest = createCancelableRequest(TodoRequestMixin.name);

  protected todoCreatedId = 0;
  protected todoCreateSuccess = false;
  protected todoCreateError: Record<any, any> | null = null;
  private todoCreateLoadingQueue = 0;

  public get todoCreateLoading() {
    return this.todoCreateLoadingQueue > 0;
  }

  public async createTodo(data: any): Promise<void> {
    this.todoCreateLoadingQueue++;
    this.todoCreateSuccess = false;
    this.todoCreateError = null;
    return await this.todoCreateRequest
      .makeRequest({ url: `/todos`, method: "post", data })
      .then((response: AxiosResponse) => {
        this.todoCreatedId = response.data;
        this.todoCreateSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.todoCreateError = error.response?.data;
      })
      .finally(() => {
        this.todoCreateLoadingQueue--;
      });
  }

  private todoUploadAttachmentRequest = createCancelableRequest(TodoRequestMixin.name);

  protected todoUploadAttachmentSuccess = false;
  protected todoUploadAttachmentError: Record<any, any> | null = null;
  private todoUploadAttachmentLoadingQueue = 0;

  public get todoUploadAttachmentLoading() {
    return this.todoUploadAttachmentLoadingQueue > 0;
  }

  public async todoUploadAttachment(todoId: number, data: FormData): Promise<void> {
    this.todoUploadAttachmentLoadingQueue++;
    this.todoUploadAttachmentSuccess = false;
    this.todoUploadAttachmentError = null;
    return await this.todoUploadAttachmentRequest
      .makeRequest({ url: `/todos/${todoId}/attachment`, method: "post", data })
      .then((response: AxiosResponse) => {
        this.todoUploadAttachmentSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.todoUploadAttachmentError = error.response?.data;
      })
      .finally(() => {
        this.todoUploadAttachmentLoadingQueue--;
      });
  }
}
