



























































































import { Component, Prop, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import SaldoMixin from "@/mixins/SaldoMixin";

@Component({
  components: {},
})
export default class CostBearersBalanceBottom extends mixins(SaldoMixin) {
  public name = "CostBearersBalanceBottom";

  @Prop()
  public saldo!: any;

  @Prop()
  public loading!: any;

  public selectedSaldo: any = [];
  public mounted() {
    if (this.saldoOptions && this.saldoOptions.length > 0) {
      this.selectedSaldo = this.saldoOptions[0];
    }
  }

  public defineColor(data: any) {
    if (data < 0) {
      return "bg-danger";
    }
    return "bg-success";
  }

  public get saldoOptions(): Array<any> {
    if (!this.saldo) {
      return [];
    }
    return this.saldo.map((opt: any) => {
      opt.year = opt.year !== null ? opt.year : this.$t("todo.all");
      return opt;
    });
  }

  @Watch("saldo")
  public onCostBearerSaldoChange(saldo: any): void {
    if (saldo) {
      this.selectedSaldo = saldo[0];
    }
  }
}
