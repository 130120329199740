import { Component, Vue } from "vue-property-decorator";
import { AxiosError, AxiosResponse } from "axios";
import createCancelableRequest from "@/utils/axiosCancelable";
import { IAppointment } from "@/interfaces/Student/IAppointment";

const URL = "appointments";

@Component
export default class AppointmentRequestMixin extends Vue {
  private appointmentCancelableRequest = createCancelableRequest(AppointmentRequestMixin.name);

  public appointmentsByStudent: Array<IAppointment> = [];
  private appointmentsByStudentLoadingQueue = 0;
  public appointmentsByStudentSuccess = false;
  public appointmentsByStudentError: Record<any, any> | null = null;
  public get appointmentsByStudentLoading() {
    return this.appointmentsByStudentLoadingQueue > 0;
  }

  public async findAppointmentsByStudent(studentId: number): Promise<void> {
    this.appointmentsByStudentLoadingQueue++;
    this.appointmentsByStudentSuccess = false;
    this.appointmentsByStudentError = null;
    return await this.appointmentCancelableRequest
      .makeRequest({ url: `${URL}/${studentId}`, method: "post" })
      .then((response: AxiosResponse) => {
        this.appointmentsByStudent = response.data;
        this.appointmentsByStudentSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.appointmentsByStudentError = error.response?.data;
      })
      .finally(() => {
        this.appointmentsByStudentLoadingQueue--;
      });
  }

  public destroyed() {
    this.appointmentCancelableRequest.cancelPreviousRequest();
  }
}
