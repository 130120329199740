






















import { Component, Prop, Vue } from "vue-property-decorator";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import Table from "@/components/Table.vue";
import { formatStudentName } from "@/utils/NameUtil";

@Component({
  methods: { formatStudentName },
  components: { FscSimpleCard, Table },
})
export default class CostBearersAppointments extends Vue {
  public name = "CostBearersAppointments";

  private fields = [
    {
      key: "date",
      label: this.$t("calendar.form_date"),
    },
    {
      key: "time",
      label: this.$t("calendar.form_time"),
    },
    {
      key: "student",
      label: this.$t("students.student"),
    },
    {
      key: "name",
      label: this.$t("automatic_payment.subject_type_app"),
    },
    {
      key: "standardProductShortName",
      label: this.$t("product.assignment"),
    },
    {
      key: "instructorInitials",
      label: this.$t("general.driving_instructor"),
    },
    {
      key: "duration",
      label: this.$t("general.min"),
    },
  ];

  @Prop()
  public appointments!: any;

  public get appointmentsData(): Array<any> {
    return this.appointments?.length ? this.appointments : [];
  }
}
