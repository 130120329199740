import { Component, Vue } from "vue-property-decorator";
import { AxiosError, AxiosResponse } from "axios";
import axios from "@/utils/axios";

const URL = "/training-contract";

@Component
export default class TrainingContractRequestMixin extends Vue {
  public uploadStudentSignatureError: Record<any, any> | null = null;
  public uploadStudentSignatureSuccess = false;
  public uploadStudentSignatureLoading = false;

  public uploadParentSignatureError: Record<any, any> | null = null;
  public uploadParentSignatureSuccess = false;
  public uploadParentSignatureLoading = false;

  public signedError: Record<any, any> | null = null;
  public signedSuccess = false;
  public signedLoading = false;
  public signedResponse: null | string = null;
  public signedProofSuccess = false;

  public addonsCheckboxTextError: Record<any, any> | null = null;
  public addonsCheckboxTextSuccess = false;
  public addonsCheckboxTextLoading = false;
  public addonsCheckboxTextResponse: null | string = null;

  public gdprUrlCheckboxTextError: Record<any, any> | null = null;
  public gdprUrlCheckboxTextSuccess = false;
  public gdprUrlCheckboxTextLoading = false;
  public gdprUrlCheckboxTextResponse: null | string = null;

  public documentSignedError: Record<any, any> | null = null;
  public documentSignedResponse: null | string = null;

  public uploadProofOfTrainingStudentSignatureError: Record<any, any> | null = null;
  public uploadProofOfTrainingStudentSignatureSuccess = false;
  public uploadProofOfTrainingStudentSignatureLoading = false;

  public async uploadStudentSignature(studentEducationId: number, data: FormData): Promise<void> {
    this.uploadStudentSignatureLoading = true;
    this.uploadStudentSignatureSuccess = false;
    this.uploadStudentSignatureError = null;

    return await axios
      .post(`${URL}/upload-student-signature?studentEducationId=${studentEducationId}`, data)
      .then(() => {
        this.uploadStudentSignatureSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.uploadStudentSignatureError = error.response?.data;
      })
      .finally(() => {
        this.uploadStudentSignatureLoading = false;
      });
  }

  public async uploadParentSignature(studentEducationId: number, data: FormData): Promise<void> {
    this.uploadParentSignatureLoading = true;
    this.uploadParentSignatureSuccess = false;
    this.uploadParentSignatureError = null;

    return await axios
      .post(`${URL}/upload-parent-signature?studentEducationId=${studentEducationId}`, data)
      .then(() => {
        this.uploadParentSignatureSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.uploadParentSignatureError = error.response?.data;
      })
      .finally(() => {
        this.uploadParentSignatureLoading = false;
      });
  }

  public async createSigned(studentEducationId: number, data: any = {}): Promise<void> {
    this.signedLoading = true;
    this.signedSuccess = false;
    this.signedError = null;
    return await axios
      .post(`${URL}/create-signed?studentEducationId=${studentEducationId}`, data, {
        responseType: "arraybuffer",
        headers: {
          Accept: "application/pdf",
        },
      })
      .then((response: AxiosResponse) => {
        const mimeType = response.headers["content-type"].toLowerCase();
        const blob = new Blob([response.data], { type: mimeType });
        this.signedResponse = window.URL.createObjectURL(blob);
        this.signedSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.signedError = error.response?.data;
      })
      .finally(() => {
        this.signedLoading = false;
      });
  }

  public async addonsCheckboxText(studentEducationId: number): Promise<void> {
    this.addonsCheckboxTextLoading = true;
    this.addonsCheckboxTextSuccess = false;
    this.addonsCheckboxTextError = null;
    return await axios
      .get(`${URL}/get-addons-checkbox-text?studentEducationId=${studentEducationId}`)
      .then((response: AxiosResponse) => {
        this.addonsCheckboxTextResponse = response.data;
        this.addonsCheckboxTextSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.addonsCheckboxTextError = error.response?.data;
      })
      .finally(() => {
        this.addonsCheckboxTextLoading = false;
      });
  }

  public async gdprUrlCheckboxText(studentEducationId: number): Promise<void> {
    this.gdprUrlCheckboxTextLoading = true;
    this.gdprUrlCheckboxTextSuccess = false;
    this.gdprUrlCheckboxTextError = null;
    return await axios
      .get(`${URL}/get-gdpr-url-checkbox-text?studentEducationId=${studentEducationId}`)
      .then((response: AxiosResponse) => {
        this.gdprUrlCheckboxTextResponse = response.data;
        this.gdprUrlCheckboxTextSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.addonsCheckboxTextError = error.response?.data;
      })
      .finally(() => {
        this.gdprUrlCheckboxTextLoading = false;
      });
  }

  public async hasSignedDocument(studentEducationId: number): Promise<void> {
    this.documentSignedError = null;
    return await axios
      .get(`${URL}/check-for-existing-contract?studentEducationId=${studentEducationId}`)
      .then((response: AxiosResponse) => {
        this.documentSignedResponse = response.data;
      })
      .catch((error: AxiosError) => {
        this.documentSignedError = error.response?.data;
      });
  }

  public async uploadProofOfTrainingStudentSignature(studentEducationId: number, data: FormData): Promise<void> {
    this.signedLoading = true;
    this.signedProofSuccess = false;
    this.signedError = null;

    return await axios
      .post(`/proof-of-training/upload-student-signature?studentEducationId=${studentEducationId}`, data, {
        responseType: "arraybuffer",
        headers: {
          Accept: "application/pdf",
        },
      })
      .then((response: AxiosResponse) => {
        // const mimeType = response.headers["content-type"].toLowerCase();
        // const blob = new Blob([response.data], { type: mimeType });
        // this.signedResponse = window.URL.createObjectURL(blob);
        this.$toasted.success("Erfolg unterschreiben");
        this.signedProofSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.signedError = error.response?.data;
      })
      .finally(() => {
        this.signedLoading = false;
      });
  }

  public async createProofOfTrainingSigned(studentEducationId: number, data: any = {}): Promise<void> {
    this.signedLoading = true;
    this.signedSuccess = false;
    this.signedError = null;
    return await axios
      .post(`/proof-of-training?studentEducationId=${studentEducationId}`, data, {
        responseType: "arraybuffer",
        headers: {
          Accept: "application/pdf",
        },
      })
      .then((response: AxiosResponse) => {
        const mimeType = response.headers["content-type"].toLowerCase();
        const blob = new Blob([response.data], { type: mimeType });
        this.signedResponse = window.URL.createObjectURL(blob);
        this.signedSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.signedError = error.response?.data;
      })
      .finally(() => {
        this.signedLoading = false;
      });
  }
}
