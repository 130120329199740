








import { Component, Prop, Vue } from "vue-property-decorator";
import FscModal from "@/components/Modal/FscModal.vue";

@Component({
  components: { FscModal },
})
export default class CheckWorkingHoursModal extends Vue {
  public name = "CheckWorkingHoursModal";

  @Prop({ type: String, required: true })
  protected modalId!: string;
}
