



























































import { Component, Prop, Vue } from "vue-property-decorator";
import Avatar from "@/components/Avatars/Avatar.vue";
import Table from "@/components/Table.vue";
import { formatStudentName } from "@/utils/NameUtil";

@Component({
  components: { Table, Avatar },
})
export default class ParticipantsTable extends Vue {
  protected name = "ParticipantsTable";

  @Prop({ type: Array, default: () => [], required: true })
  public students!: any;

  @Prop({ type: String })
  public filtered!: any;

  @Prop({ default: () => false, type: Boolean })
  public deletable!: any;

  @Prop({ default: () => true, type: Boolean })
  public hover!: any;

  @Prop({ default: () => false, type: Boolean })
  public hasNotPermissionWrite!: boolean;

  @Prop({ default: () => false, type: Boolean })
  public isTheoryExam!: boolean;

  public studentFields = [
    {
      key: "name",
      label: "",
      sortable: false,
    },
  ];

  protected onRowDoubleClicked(item: any): void {
    this.$emit("row-dblclicked", item);
  }

  protected onRowClicked(item: any): void {
    this.$emit("row-clicked", item);
  }

  protected formatName(item: any): string {
    return `${item.studentFirstName}  ${item.studentLastName}`;
  }

  protected formatEducations(item: any): string {
    return item.studentEducations?.join(" | ") || "";
  }

  protected onDelete(item: any, index: number): void {
    this.$emit("on-delete", item, index);
  }

  protected formatStudentId(item: any) {
    if (item.student) return item.student.id;
  }

  protected formatStudentFullName(item: any) {
    return formatStudentName(item.student.firstName, item.student.lastName);
  }

  public formatStudentLicenseClass(item: any) {
    return item.licenseClass;
  }

  public formatStudentExamStatus(item: any) {
    return item.examStatus ? item.examStatus.name : "";
  }
}
