import { AxiosError, AxiosResponse } from "axios";
import { Component, Vue } from "vue-property-decorator";
import createCancelableRequest from "@/utils/axiosCancelable";

@Component
export class CostBearerPaymentsDocumentsMixin extends Vue {
  private costBearerPaymentsDocumentsRequest = createCancelableRequest(CostBearerPaymentsDocumentsMixin.name);

  public costBearerPaymentsDocuments: any = [];
  public costBearerPaymentsDocumentsTotal = 0;
  public costBearerPaymentsDocumentsError: any = null;
  public costBearerPaymentsDocumentsSuccess: any = false;
  public costBearerPaymentsDocumentsLoadingQueue = 0;

  public get costBearerPaymentsDocumentsLoading() {
    return this.costBearerPaymentsDocumentsLoadingQueue > 0;
  }

  public async fetchCostBearerPaymentsDocuments(filter: any): Promise<void> {
    this.costBearerPaymentsDocumentsLoadingQueue++;
    this.costBearerPaymentsDocumentsError = null;
    this.costBearerPaymentsDocumentsSuccess = false;
    return await this.costBearerPaymentsDocumentsRequest
      .makeRequest({ url: `payment-documents/filter`, data: filter, method: "post" })
      .then((response: AxiosResponse) => {
        this.costBearerPaymentsDocumentsSuccess = true;
        this.costBearerPaymentsDocuments = response.data?.data || [];
        this.costBearerPaymentsDocumentsTotal = response.data?.total || 0;
      })
      .catch((error: AxiosError) => {
        this.costBearerPaymentsDocumentsError = error.response?.data;
      })
      .finally(() => {
        this.costBearerPaymentsDocumentsLoadingQueue--;
      });
  }

  // cancel payment document
  private cancelPaymentDocumentRequest = createCancelableRequest(CostBearerPaymentsDocumentsMixin.name);

  public cancelPaymentDocumentSuccess = false;
  public cancelPaymentDocumentError: Record<any, any> | null = null;
  private cancelPaymentDocumentLoadingQueue = 0;

  public get cancelPaymentDocumentLoading() {
    return this.cancelPaymentDocumentLoadingQueue > 0;
  }

  public async cancelPaymentDocumentAction(invoiceForCancellationId: number): Promise<void> {
    this.cancelPaymentDocumentLoadingQueue++;
    this.cancelPaymentDocumentSuccess = false;
    this.cancelPaymentDocumentError = null;

    return await this.cancelPaymentDocumentRequest
      .makeRequest({
        method: "post",
        url: `/payment-documents/create-cancellation-invoice?invoiceForCancellationId=${invoiceForCancellationId}`,
      })
      .then((response: AxiosResponse) => {
        this.cancelPaymentDocumentSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.cancelPaymentDocumentError = error.response?.data;
      })
      .finally(() => {
        this.cancelPaymentDocumentLoadingQueue--;
      });
  }
}
