



















































































import { Component, Prop, Inject, InjectReactive } from "vue-property-decorator";
import ImgBox from "@/views/CostBearers/Info/ImgBox.vue";
import { ICostBearer } from "@/interfaces/ICostBearer";
import { CLASSIC_PAY, LIVE_PAY } from "@/constants/PaymentWorkflow";
import { mixins } from "vue-class-component";
import CostBearerMixin from "@/mixins/CostBearerMixin";
import SaldoMixin from "@/mixins/SaldoMixin";

@Component({
  components: { ImgBox },
})
export default class CostBearersInfoCard extends mixins(CostBearerMixin, SaldoMixin) {
  public name = "CostBearersInfoCard";

  @Prop()
  public saldo!: any;

  @Prop()
  public bearer!: ICostBearer;

  private get allSaldo() {
    return this.saldo?.filter((sld: any) => sld.year === null) || [];
  }

  private get costBearer() {
    return this.bearer || ({} as ICostBearer);
  }

  public viewInfo = false;

  // archive
  @Inject("archiveCostBearerAction")
  public archive!: (costBearerId: number, archived: boolean) => Promise<void>;

  @InjectReactive("archiveCostBearerSuccess")
  public getArchiveSuccess!: boolean;

  @Inject("fetchCostBearers")
  protected fetchCostBearers!: () => void;

  public defineColor(data: any) {
    if (data < 0) {
      return "text-danger";
    }
    return "text-success";
  }

  public viewCostBearerInfo(): void {
    this.viewInfo = !this.viewInfo;
  }

  private async onClickArchive(): Promise<void> {
    const costBearerId = this.bearer?.id;

    if (costBearerId) {
      await this.archive(costBearerId, !this.bearer.archived);

      if (this.getArchiveSuccess) {
        this.fetchCostBearers();
      }
    }
  }

  private get getImg(): any {
    if (this.costBearer.paymentWorkflowId == CLASSIC_PAY) {
      return require("@/assets/Payment_Classic.png");
    } else if (this.costBearer.paymentWorkflowId == LIVE_PAY) {
      return require("@/assets/Payment_Live.png");
    }
    return null;
  }
}
