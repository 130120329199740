
























































































































import { Component, InjectReactive, Prop } from "vue-property-decorator";
import Datepicker from "@/components/Datepicker.vue";
import AbortButton from "@/components/Button/AbortButton.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import Validation from "@/components/Validation.vue";
import FscInputDecimal from "@/components/Field/FscInputDecimal.vue";
import moment from "moment";
import { PAYMENT_METHODS } from "@/constants/PaymentMethods";
import { IStudentEducation } from "@/interfaces/Student/IStudentEducation";
import { formatStudentName } from "@/utils/NameUtil";
import { PropType } from "vue";
import { Validations } from "vuelidate-property-decorators";
import { minValue, required } from "vuelidate/lib/validators";
import { mixins } from "vue-class-component";
import VatRequestMixin from "@/mixins/Request/VatRequestMixin";

@Component({
  components: {
    Datepicker,
    AbortButton,
    SaveButton,
    Validation,
    FscInputDecimal,
  },
})
export default class StudentBalanceTop extends mixins(VatRequestMixin) {
  public name = "StudentBalanceTop";

  @Prop({ type: Array as PropType<Array<IStudentEducation>>, default: () => [] })
  public studentEducations!: Array<IStudentEducation>;

  @Prop({ default: () => false })
  public loading!: boolean;

  @InjectReactive("isDisabled")
  public disabled!: boolean;

  @InjectReactive("selectedStudent")
  public selectedStudent!: any;

  public submitDisabled = false;

  private paymentData: any = {
    studentEducation: null,
    description: "",
    date: moment().format("YYYY-MM-DD"),
    amountInEur: null,
    paymentMethod: null,
    refund: false,
    vat: null,
  };

  private requestInProgress = false;
  @Validations()
  public validations(): Record<string, any> {
    return {
      paymentData: {
        studentEducation: { required },
        date: { required },
        amountInEur: {
          required,
          minValueCustom: minValue(0),
        },
        vat: { required },
        paymentMethod: { required },
      },
    };
  }

  public get paymentMethodOptions(): Array<any> {
    return PAYMENT_METHODS;
  }

  public get educationOptions(): Array<IStudentEducation> {
    if (!this.studentEducations || !Array.isArray(this.studentEducations)) {
      return [];
    }
    return this.studentEducations.filter((option: IStudentEducation) => !option.archived);
  }

  public get initialDate(): any {
    return moment().format("YYYY-MM-DD");
  }

  public mounted(): void {
    this.findAllVats();
    const options = this.educationOptions;

    if (options.length === 1) {
      this.paymentData.studentEducation = options[0];
      this.setVatByLicenseClass(this.educationOptions[0].mainLicenseClass);
    }
    this.paymentData.paymentMethod = PAYMENT_METHODS[1];
  }

  public onAbort(): void {
    this.$emit("on-abort");
  }

  public onSubmit(): void {
    // if (this.disabled) return;

    this.$v.$touch();

    if (this.$v.$invalid) return;

    this.submitDisabled = true;
    this.requestInProgress = true;

    this.$emit("on-submit", this.paymentData);

    setTimeout(() => {
      this.submitDisabled = false;
      this.requestInProgress = false;
    }, 5000);
  }

  protected onSelectLicenseClass(selectedLicenseClass: IStudentEducation): void {
    if (selectedLicenseClass?.mainLicenseClass) {
      this.setVatByLicenseClass(selectedLicenseClass.mainLicenseClass);
    }
  }

  protected setVatByLicenseClass(licenseClass: string) {
    const foundedStudentEducation = this.studentEducations.find((x: IStudentEducation) => x.mainLicenseClass === licenseClass);

    if (foundedStudentEducation) {
      this.paymentData.vat = foundedStudentEducation.vat;
    }
  }

  private get studentName() {
    return formatStudentName(this.selectedStudent.firstName, this.selectedStudent.lastName);
  }
}
