export const CASH = 1;
export const BANK = 2;

export const PAYMENT_METHODS = [
  {
    id: CASH,
    name: "Bar",
  },
  {
    id: BANK,
    name: "Bank",
  },
];
