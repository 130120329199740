<template>
  <button class="bg-transparent border-0 hover:bg-star-dust-200 rounded-circle h-30 w-30" @click="$emit('click')">
    <font-awesome-icon :icon="['fat', 'file-pdf']" />
  </button>
</template>

<script>
export default {
  name: "PdfTableButton",
};
</script>
