




import { Component, Vue } from "vue-property-decorator";
import ActionButton from "@/components/Button/ActionButton.vue";
@Component({
  components: { ActionButton },
})
export default class SaldoButton extends Vue {
  public name = "SaldoButton";
}
