











































































//@ts-ignore
import VueSignaturePad from "vue-signature-pad";
import { Component, Prop } from "vue-property-decorator";
import AbortButton from "@/components/Button/AbortButton.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import { dataURLtoFile, getFile } from "@/utils/File";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import { AxiosError } from "axios";
import TheoryMixin from "@/mixins/TheoryMixin";
import { mixins } from "vue-class-component";
import eventBus from "@/plugins/EventBus";
import { checkFileSizeBeforeUpload } from "@/helper/files";
import { UploadConfiguration } from "@/enums/UploadConfiguration";
import { formatStudentName } from "../../utils/NameUtil";

@Component({
  methods: { formatStudentName },
  components: { FscSimpleCard, AbortButton, SaveButton },
})
export default class SignatureCapture extends mixins(TheoryMixin) {
  public name = "SignatureCapture";

  @Prop()
  public selectedLesson: any;

  @Prop()
  public students: any;

  @Prop()
  public eventName: any;

  @Prop({ type: String, required: false, default: "" })
  public getSignResourceUrl!: any;

  @Prop({ type: Boolean, default: () => false })
  public loading!: any;

  public selectedLessonId: any;
  public selectedStudentId: any;

  public rowSelected = false;

  public signatureDirty = false;
  public hasSignature = false;
  public signatureFile = "";

  public tableFields = [
    {
      key: "studentName",
      label: "",
    },
    {
      key: "signed",
      label: "",
    },
  ];
  public selectedStudent: any = null;
  private fileSizeInvalid = false;

  public get dataHasOnlyOneRow() {
    return this.students?.length === 1;
  }

  public mounted(): void {
    if (this.selectedLesson && this.students && this.students?.length === 1) {
      this.rowSelected = true;
      this.selectedStudent = this.students[0];
      this.selectedStudentId = this.selectedStudent.id;
      this.selectedLessonId = this.selectedLesson.id;
    }
  }
  public rowClass(item: any, type: any) {
    if (!item || type !== "row") return;
    if (this.students?.length === 1) return "b-table-row-selected table-active b-table-selecting";
  }

  public onPngSignatureUploadSubmit(file: any): void {
    let formData = new FormData();
    formData.append("file", file);
    const options = {
      data: formData,
    };
    this.fileSizeInvalid = !checkFileSizeBeforeUpload(file, UploadConfiguration.SIZE_CONFIGURATION);
    if (file && this.fileSizeInvalid) return;

    eventBus.$emit(this.eventName + "-on-signature-capture-complete", options);
  }

  public async onRowSelected(row: any): Promise<void> {
    if (this.signatureDirty && !this.dataHasOnlyOneRow) {
      this.onSignatureSignSubmit(false);
      await this.onSignatureClearPad();
    }

    if (row) {
      const selectedRow = row;
      this.hasSignature = selectedRow.signed;
      if (selectedRow.signed && this.getSignResourceUrl) {
        const options = {
          resource: this.getSignResourceUrl,
          id: selectedRow.id,
        };
        this.fetchSignature(options);
      }
      this.rowSelected = true;
      this.selectedStudentId = selectedRow.id;
      this.selectedLessonId = this.selectedLesson.id;
    } else {
      this.rowSelected = false;
    }
  }

  public fetchSignature(options: any): void {
    const { id, resource } = options;
    getFile(
      {
        method: "GET",
        url: `${resource}=${id}`,
      },
      false
    )
      .then((blob: any) => {
        this.signatureFile = blob;
        this.hasSignature = !!blob;
        this.rowSelected = true;

        this.$nextTick(() => {
          if (!this.hasSignature && this.signatureDirty) {
            (this.$refs.signaturePad as VueSignaturePad).clearSignature();
          }
        });
      })
      .catch((error: AxiosError) => {
        //
      });
  }

  public async onSignatureClearPad(): Promise<void> {
    (this.$refs.signaturePad as VueSignaturePad).clearSignature();
    this.signatureDirty = false;
  }

  public onDeleteSignature(): void {
    this.onSignatureClearPad();
    // eventBus.$emit(this.eventName + "-on-signature-capture-complete", null);
  }

  public onSignatureSignSubmit(fetchSignatureAfterSubmit: boolean): void {
    if (this.hasNotPermissionWrite) return;

    const { isEmpty, data } = (this.$refs.signaturePad as VueSignaturePad).saveSignature();
    if (!isEmpty) {
      let pngSignature = dataURLtoFile(data, this.selectedStudentId);
      this.onPngSignatureUploadSubmit(pngSignature);
    } else {
      eventBus.$emit(this.eventName + "-on-signature-capture-complete", null);
    }
  }

  public onBegin(): void {
    (this.$refs.signaturePad as VueSignaturePad).resizeCanvas();
    this.signatureDirty = true;
  }
}
