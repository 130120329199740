import { Component, Provide, Vue } from "vue-property-decorator";
import { getFile } from "@/utils/File";
import { ILivePayMandate } from "@/interfaces/ILivePayMandate";

@Component
export default class StudentInfoMixin extends Vue {
  protected visibleMandatedPreview = false;
  public mandatePdfFile = "";
  protected mandate: ILivePayMandate | null = null;

  public fetchStudentDocument(studentDocumentId: number): void {
    getFile(
      {
        method: "GET",
        url: `student-documents/${studentDocumentId}`,
      },
      false
    ).then((blob: any) => {
      this.mandatePdfFile = blob;
      this.mandateSetVisible(true);
    });
  }

  @Provide("mandateClick")
  private mandateClick(item: ILivePayMandate): void {
    if (item && item.documentId) {
      this.mandate = item;
      this.fetchStudentDocument(item.documentId);
    }
  }

  @Provide("mandateRevokeClick")
  private mandateRevokeClick(item: ILivePayMandate): void {
    if (item && item.revocationDocumentId) {
      this.mandate = item;
      this.fetchStudentDocument(item.revocationDocumentId);
    }
  }

  @Provide("mandateSetVisible")
  private mandateSetVisible(value: boolean): void {
    this.visibleMandatedPreview = value;
  }
}
