import { Component, Vue } from "vue-property-decorator";
import createCancelableRequest from "@/utils/axiosCancelable";
import { AxiosError, AxiosResponse } from "axios";

@Component
export default class RegionSpecificDocumentRequestMixin extends Vue {
  protected regionSpecificDocumentCancelableRequest = createCancelableRequest(RegionSpecificDocumentRequestMixin.name);

  public regionSpecificDocuments: Array<any> = [];
  public regionSpecificDocumentsLoadingQueue = 0;
  public regionSpecificDocumentsSuccess = false;
  public regionSpecificDocumentsError = null;

  public get regionSpecificDocumentsLoading() {
    return this.regionSpecificDocumentsLoadingQueue > 0;
  }

  public async findAllRegionSpecificDocuments() {
    this.regionSpecificDocumentsLoadingQueue++;
    this.regionSpecificDocumentsSuccess = false;
    this.regionSpecificDocumentsError = null;

    return await this.regionSpecificDocumentCancelableRequest
      .makeRequest({ url: `region-specific-documents/driving-school/current`, method: "get" })
      .then((response: AxiosResponse) => {
        this.regionSpecificDocuments = response.data;
        this.regionSpecificDocumentsSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.regionSpecificDocumentsError = error.response?.data;
      })
      .finally(() => {
        this.regionSpecificDocumentsLoadingQueue--;
      });
  }

  public destroyed() {
    this.regionSpecificDocumentCancelableRequest.cancelPreviousRequest();
  }
}
