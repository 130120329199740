


























































































































































import { Component, Inject, InjectReactive, Prop, Watch } from "vue-property-decorator";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import { ILivePayMandate } from "@/interfaces/ILivePayMandate";
import PdfTableButton from "@/components/Button/PdfTableButton.vue";
import CreateButton from "@/components/Button/CreateButton.vue";
import FscModal from "@/components/Modal/FscModal.vue";
import AbortButton from "@/components/Button/AbortButton.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import FscMultiselect from "@/components/FscMultiselect.vue";
import MailButton from "@/components/Button/MailButton.vue";
import Table from "@/components/Table.vue";
import { formatStudentName } from "@/utils/NameUtil";
import { PropType } from "vue";
import { IStudent } from "@/interfaces/Student/IStudent";
import { mixins } from "vue-class-component";
import LivePayMandateRequestMixin from "@/mixins/Request/LivePayMandateRequestMixin";
import { IStudentEducation } from "@/interfaces/Student/IStudentEducation";
import { IBasicNamedDTO } from "@/interfaces/IBasicNamedDTO";

@Component({
  methods: { formatStudentName },
  components: {
    PdfTableButton,
    FscSimpleCard,
    CreateButton,
    FscModal,
    AbortButton,
    SaveButton,
    FscMultiselect,
    MailButton,
    Table,
  },
})
export default class MandateInfo extends mixins(LivePayMandateRequestMixin) {
  public name = "MandateInfo";

  @Prop({ type: Boolean, default: () => false })
  public loading!: boolean;

  @Prop({ type: Array as PropType<Array<IStudentEducation>> })
  public studentEducations!: Array<IStudentEducation>;

  @Prop({ type: Object as PropType<IStudent> })
  public student!: IStudent;

  protected fields = [
    {
      key: "date",
      label: this.$t("general.created_at"),
      sortable: false,
    },
    {
      key: "time",
      label: this.$t("calendar.form_time"),
      sortable: false,
    },
    {
      key: "licenseClass",
      label: this.$t("sidebar.training"),
      sortable: false,
    },
    {
      key: "mandateHolder",
      label: this.$t("general.mandate_holder"),
      sortable: false,
    },
    {
      key: "enabled",
      label: this.$tc("general.active", 1),
      sortable: false,
    },
    {
      key: "status",
      label: "Status",
      sortable: false,
    },
    {
      key: "isRevoked",
      label: "Widerruf",
      sortable: false,
    },
  ];

  public selectedEducation: IBasicNamedDTO | null = null;
  public selectedEmail: any = "";
  public selectedMandateId: any = "";
  public educationIsSingle = false;

  private cancelMandateConfirmationModalId = "cancelMandateConfirmationModalId";
  private cancelMandateModalId = "cancelMandateModalId";
  private emailModalId = "emailModalId";
  private mandateModalId = "mandateModalId";

  @InjectReactive("drivingSchoolEnabled")
  public drivingSchoolEnabled!: any;

  @Inject("mandateClick")
  public mandateClick!: (item: ILivePayMandate) => void;

  @Inject("mandateRevokeClick")
  public mandateRevokeClick!: (item: ILivePayMandate) => void;

  private get mandateIsLoading() {
    return this.createLivePayMandateGoCardlessLoading;
  }

  protected mandateClicked(item: ILivePayMandate): void {
    this.mandateClick(item);
  }

  public get mandates() {
    return this.livePayMandatesByStudent;
  }

  protected mandateCreateFormModalShow() {
    this.$bvModal.show(this.mandateModalId);
  }

  protected onAbortMandateForm() {
    this.$bvModal.hide(this.mandateModalId);
    this.selectedEducation = null;
  }

  protected async onSubmitMandateForm() {
    const id = this.selectedEducation?.id ? this.selectedEducation.id : 0;
    await this.createLivePayMandateGoCardless(id);
    if (this.createLivePayMandateGoCardlessLink) window.open(this.createLivePayMandateGoCardlessLink);
    this.$bvModal.hide(this.mandateModalId);
  }

  @Watch("activeEducationsOptions", { immediate: true, deep: true })
  public onSelectedEducations(educations: Array<IBasicNamedDTO>) {
    if (educations.length) {
      this.selectedEducation = this.activeEducationsOptions[0];
    }
  }

  private get activeEducationsOptions() {
    return this.studentEducations.map((education: any) => {
      return {
        id: education.id,
        name: education.mainLicenseClass,
      };
    });
  }

  @Watch("student", { deep: true, immediate: true })
  public onStudentWatcher(student: IStudent) {
    if (student?.id) {
      this.selectedEmail = student.email ? student.email : "";
      this.findLivePayMandatesByStudent(student.id);
    }
  }

  public setCircleColor(row: any) {
    if (row.enabled === true) {
      return `background-color: #17A533`;
    } else {
      return `background-color: #DC0C23`;
    }
  }

  public onEmailModalShow() {
    this.$bvModal.show(this.emailModalId);
  }

  public onEmailModalAbort() {
    this.$bvModal.hide(this.emailModalId);
  }

  public onEmailSubmit() {
    const educationId = this.selectedEducation?.id ? this.selectedEducation.id : 0;
    const email = this.selectedEmail ? this.selectedEmail : "";
    this.sendLivePayMandateEmail(email, educationId);
    this.$bvModal.hide(this.emailModalId);
    if (this.student?.id) {
      this.findLivePayMandatesByStudent(this.student.id);
    }
  }

  public onCancelMandateModalShow(mandate: any) {
    this.selectedMandateId = mandate.id ? mandate.id : "";
    const moreThanOneEducation: any = [];
    this.studentEducations.map((education: any) => {
      if (education.id === mandate.studentEducationId) {
        moreThanOneEducation.push(education);
      }
    });
    if (moreThanOneEducation.length === 1) {
      this.educationIsSingle = true;
    } else {
      this.educationIsSingle = false;
    }
    this.$bvModal.show(this.cancelMandateModalId);
  }

  private async cancelMandate(mandateId: number) {
    await this.cancelLivePayMandate(mandateId);
    if (this.cancelLivePayMandateSuccess && this.student?.id) {
      await this.findLivePayMandatesByStudent(this.student.id);
    }
  }

  private async onCancelMandateSubmit() {
    const mandateId = this.selectedMandateId;
    const educationIsSingle = this.educationIsSingle;

    this.$bvModal.hide(this.cancelMandateModalId);

    if (educationIsSingle) {
      this.$bvModal.show(this.cancelMandateConfirmationModalId);
    } else {
      this.cancelMandate(mandateId);
    }
  }

  private onCancelMandateAbort() {
    this.selectedMandateId = "";
    this.educationIsSingle = false;
    this.$bvModal.hide(this.cancelMandateModalId);
  }

  private async onCancelMandateConfirmationSubmit() {
    const mandateId = this.selectedMandateId;
    await this.cancelMandate(mandateId);
    this.$bvModal.hide(this.cancelMandateConfirmationModalId);
  }

  private onCancelMandateConfirmationAbort() {
    this.$bvModal.hide(this.cancelMandateConfirmationModalId);
  }

  private get mandateInfoLoading() {
    return this.loading || this.livePayMandatesByStudentLoading;
  }
}
