















































































































































































import { Component, Prop, Watch } from "vue-property-decorator";
import Datepicker from "@/components/Datepicker.vue";
import FscMultiselect from "@/components/FscMultiselect.vue";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import { IBasicNamedDTO } from "@/interfaces/IBasicNamedDTO";
import { IBasicUser } from "@/interfaces/IBasicUser";
import { IGroup } from "@/interfaces/IGroup";
import { mixins } from "vue-class-component";
import BaseMixin from "@/mixins/BaseMixin";
import SaveButton from "@/components/Button/SaveButton.vue";
import AbortButton from "@/components/Button/AbortButton.vue";
import UserService from "@/services/UserService";
import Validation from "@/components/Validation.vue";
import { Validations } from "vuelidate-property-decorators";
import { required } from "vuelidate/lib/validators";
import { checkFileSizeBeforeUpload } from "@/helper/files";
import { UploadConfiguration } from "@/enums/UploadConfiguration";
import InstructorRequestMixin from "@/mixins/Request/InstructorRequestMixin";
import { namespace } from "vuex-class";
import TodoRequestMixin from "@/mixins/Request/TodoRequestMixin";

const TodoModule = namespace("todo");

@Component({
  components: {
    Datepicker,
    FscMultiselect,
    FscSimpleCard,
    SaveButton,
    AbortButton,
    Validation,
  },
})
export default class CostBearersToDo extends mixins(BaseMixin, InstructorRequestMixin, TodoRequestMixin) {
  public name = "CostBearersToDo";

  @TodoModule.Action("findPriorities")
  public findPriorities!: () => Promise<void>;

  @TodoModule.Getter("getPriorities")
  public priorities!: Array<any>;

  @TodoModule.Action("findStatuses")
  public findStatuses!: () => Promise<void>;

  @TodoModule.Getter("getStatuses")
  public statuses!: Array<IBasicNamedDTO>;

  @TodoModule.Action("findGroups")
  public findGroups!: () => Promise<void>;

  @TodoModule.Getter("getGroups")
  public groups!: Array<IGroup>;

  @TodoModule.Getter("getIsLoading")
  public todoLoading!: boolean;

  @Prop({ type: Array, default: () => [], required: true })
  public costBearers!: any;

  @Prop()
  public selectedCostBearerRow!: any;

  public selectedDueDate: any = null;
  public selectedStatus: any = null;
  public selectedSubject: any = null;
  public selectedTask: any = null;
  public selectedAuthor: IBasicUser | null = null;
  public selectedUser: IBasicUser | null = null;
  public selectedCostBearer: any = null;
  public selectedPriority: any = null;
  public selectedGroup: IGroup | null = null;
  public selectedAttachment: File | null = null;
  public selectedAttachmentName: any = null;
  public fileSizeInvalid = false;

  public mounted(): void {
    this.filterInstructor();
    this.fetchStatuses();
    this.findGroups();
    this.fetchPriorities();
    this.selectedAuthor = this.currentUser;
  }

  public async fetchStatuses() {
    await this.findStatuses();
    const defaultStatus = this.statuses.filter((status: any) => status.id === 100);
    this.selectedStatus = this.statuses.length ? defaultStatus[0] : null;
  }

  public async fetchPriorities() {
    await this.findPriorities();
    const defaultPriority = this.priorities.filter((priority: any) => priority.id === 100);
    this.selectedPriority = this.priorities.length ? defaultPriority[0] : null;
  }

  public attachmentSelected(): void {
    this.fileSizeInvalid = false;
    const attachment = (this.$refs.attachment as HTMLFormElement).files[0];
    if (attachment) {
      this.selectedAttachmentName = (this.$refs.attachment as HTMLFormElement).files[0].name;
      this.fileSizeInvalid = !checkFileSizeBeforeUpload(attachment, UploadConfiguration.SIZE_NORMAL);
    } else {
      this.selectedAttachmentName = null;
    }
    this.selectedAttachment = attachment;
  }

  public get currentUser(): any {
    const user = UserService.getUser();
    return {
      id: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      initials: user.initials,
    };
  }

  public get costBearersOptions(): any {
    if (this.costBearers) {
      return this.costBearers.map((bearer: any) => {
        return {
          id: bearer.id,
          name: bearer.name,
        };
      });
    }
    return [];
  }

  public onAbortTodo(): void {
    this.fileSizeInvalid = false;
    this.$emit("todo-abort");
  }

  public get selectedAuthorFormat() {
    if (this.selectedAuthor) {
      return {
        id: this.selectedAuthor.id,
        firstName: this.selectedAuthor.firstName,
        lastName: this.selectedAuthor.lastName,
        initials: this.selectedAuthor.initials,
      };
    }
    return null;
  }

  public get selectedUserFormat() {
    if (this.selectedUser) {
      return {
        id: this.selectedUser.id,
        firstName: this.selectedUser.firstName,
        lastName: this.selectedUser.lastName,
        initials: this.selectedUser.initials,
      };
    }
    return null;
  }

  @Watch("selectedCostBearerRow", { immediate: true, deep: true })
  public onCostBearerRowChange(costBearer: any) {
    if (costBearer) {
      this.selectedCostBearer = {
        id: costBearer.id,
        name: costBearer.name,
      };
    }
  }

  @Validations()
  public validations(): Record<string, any> {
    return {
      selectedDueDate: { required },
      selectedPriority: { required },
      selectedAuthor: { required },
      selectedUser: { required },
      selectedStatus: { required },
      selectedSubject: { required },
    };
  }

  public async onSubmitTodo(): Promise<void> {
    this.$v.$touch();
    if (this.$v.$invalid) return;

    const todo = {
      subject: this.selectedSubject,
      task: this.selectedTask,
      dueDate: this.selectedDueDate,
      author: this.selectedAuthorFormat,
      user: this.selectedUserFormat,
      status: this.selectedStatus,
      group: this.selectedGroup,
      priority: this.selectedPriority,
      costBearer: this.selectedCostBearer,
      withAttachment: Boolean(this.selectedAttachment),
    };

    await this.createTodo(todo);

    if (this.todoCreatedId && this.selectedAttachment) {
      if (this.fileSizeInvalid) return;

      const formData = new FormData();
      formData.append("file", this.selectedAttachment);
      await this.todoUploadAttachment(this.todoCreatedId, formData);
      this.selectedAttachment = null;
      this.fileSizeInvalid = false;
    }

    if (this.todoCreateSuccess) {
      this.$toasted.success(this.$t("messages.save_success").toString());
      this.$emit("todo-abort");
    }
  }

  private get todoIsLoading() {
    return this.todoCreateLoading || this.todoUploadAttachmentLoading;
  }
}
