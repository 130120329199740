




import { Component, Prop, Vue } from "vue-property-decorator";
import FscModal from "@/components/Modal/FscModal.vue";

@Component({
  components: { FscModal },
})
export default class DeleteEducationModal extends Vue {
  public name = "DeleteEducationModal";

  @Prop({ type: String })
  public modalId!: string;
}
