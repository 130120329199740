




import { Component, Vue, Prop } from "vue-property-decorator";
import ActionButton from "@/components/Button/ActionButton.vue";
@Component({
  components: { ActionButton },
})
export default class QRCodeButton extends Vue {
  public name = "QRCodeButton";

  @Prop({ default: () => "button.student_qr_code", required: false, type: String })
  tooltipLabel?: any;
}
