















































































import { Component, Inject, InjectReactive, Prop, ProvideReactive, Watch } from "vue-property-decorator";
import ViewSwitchMixin from "@/mixins/ViewSwitchMixin";
import { mixins } from "vue-class-component";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import { Validations } from "vuelidate-property-decorators";
import { required, minValue } from "vuelidate/lib/validators";
import Validation from "@/components/Validation.vue";
import CostBearersInfoCard from "@/views/CostBearers/Info/CostBearersInfoCard.vue";
import CostBearersAttendees from "@/views/CostBearers/Info/CostBearersAttendees.vue";
import CostBearersBalanceTop from "@/views/CostBearers/Info/CostBearersBalanceTop.vue";
import CostBearersAppointments from "@/views/CostBearers/Info/CostBearersAppointments.vue";
import CostBearersBalanceBottom from "@/views/CostBearers/Info/CostBearersBalanceBottom.vue";
import CostBearerPaymentDocuments from "@/views/CostBearers/Info/CostBearerPaymentDocuments.vue";
import CostBearersChecks from "@/views/CostBearers/Info/CostBearersChecks.vue";
import CustomSlider from "@/components/Slider/CustomSlider.vue";
import eventBus from "@/plugins/EventBus";
import { PAYMENT_METHODS } from "@/constants/PaymentMethods";
import moment from "moment/moment";

@Component({
  components: {
    CustomSlider,
    CostBearerPaymentDocuments,
    FscSimpleCard,
    CostBearersInfoCard,
    CostBearersAttendees,
    CostBearersBalanceTop,
    Validation,
    CostBearersAppointments,
    CostBearersBalanceBottom,
    CostBearersChecks,
  },
})
export default class CostBearersInfo extends mixins(ViewSwitchMixin) {
  public name = "CostBearersInfo";

  private get middleTabs() {
    return [
      {
        label: this.$t("theory.entry_list"),
      },
      {
        label: this.$t("general.cost_invoice"),
      },
      {
        label: this.$tc("general.event", 2) + " " + "(" + this.allAppointmentsLength + ")",
      },
      {
        label: this.$t("general.checks"),
      },
      {
        label: this.$t("general.balance"),
      },
    ];
  }

  private costBearersPaymentData: any = {
    refund: false,
    description: "",
    date: null,
    amountInEur: null,
    paymentMethod: null,
  };

  public studentsPayment: any = {};
  public invoicesPayment: any = {};

  @Prop()
  public balanceActiveTab!: any;

  @Prop()
  public balanceBottomTab!: any;

  @Prop()
  public costBearer!: any;

  @Prop({ required: true })
  public selectedCostBearerId!: any;

  @Prop()
  public loading!: boolean;

  @Prop({ type: Array, default: () => [] })
  public studentEducationsByCostBearer: any;

  @Prop({ type: Array, default: () => [] })
  public costBearerUnpaidInvoices: any;

  @Prop({ type: Array, default: () => [] })
  public costBearerAppointments: any;

  @Prop({ type: Array, default: () => [] })
  public costBearerBalances: any;

  @Prop({ type: Boolean, default: () => false })
  public disabled!: boolean;

  @ProvideReactive("isDisabled")
  private get isDisabled(): boolean {
    return this.disabled;
  }

  // create cost bearer payment
  @Inject("createCostBearerPaymentAction")
  private createCostBearerPaymentAction!: (data: any) => Promise<void>;

  @InjectReactive("createCostBearerPaymentSuccess")
  private createCostBearerPaymentSuccess!: boolean;

  @InjectReactive("createCostBearerPaymentLoading")
  private createCostBearerPaymentLoading!: boolean;

  // cancel cost bearer payment document
  @Inject("cancelPaymentDocumentAction")
  private cancelInvoiceCostBearer!: (invoiceForCancellationId: number) => Promise<void>;

  @InjectReactive("cancelPaymentDocumentSuccess")
  private cancelPaymentDocumentSuccess!: boolean;

  // switch invoice to paid
  @Inject("invoiceSwitchPaidAction")
  private invoiceSwitchPaidAction!: (invoiceId: number) => Promise<void>;

  @InjectReactive("invoiceSwitchPaidLoading")
  private invoiceSwitchPaidLoading!: boolean;

  @InjectReactive("invoiceSwitchPaidSuccess")
  private invoiceSwitchPaidSuccess!: boolean;

  @InjectReactive("costBearerLoading")
  private costBearerLoading!: boolean;

  @InjectReactive("costBearerBalancesLoading")
  private costBearerBalancesLoading!: boolean;

  @InjectReactive("costBearerAppointmentsLoading")
  private costBearerAppointmentsLoading!: boolean;

  @InjectReactive("costBearerUnpaidInvoicesLoading")
  private costBearerUnpaidInvoicesLoading!: boolean;

  @InjectReactive("studentEducationsByCostBearerLoading")
  private studentEducationsByCostBearerLoading!: boolean;

  @InjectReactive("archiveCostBearerLoading")
  private archiveCostBearerLoading!: boolean;

  public topCostBearerTab = 0;

  public bottomTab = 0;

  public controlSum: any = "";

  public onClickedEducationRow(student: any): void {
    this.$emit("on-clicked-education", student);
  }

  public onPdfDocumentClicked(item: any): void {
    this.$emit("pdf-document-preview", item);
  }

  public async onDotClick(item: any) {
    const id = item?.paymentDocumentId;
    if (id) {
      await this.invoiceSwitchPaidAction(id);

      if (this.invoiceSwitchPaidSuccess) {
        this.fetchCostBearerPaymentDocuments();
      }
    }
  }

  public closeView(): void {
    this.$emit("close");
  }

  public bottomTabActive(index: any, bottomTab: any) {
    if (index === bottomTab) {
      return true;
    }
    return false;
  }

  public changeBottomTab(index: number) {
    this.bottomTab = index;
    this.resetBottomTab();
  }

  public get allAppointmentsLength() {
    if (this.costBearerAppointments && this.costBearerAppointments.length > 0) {
      return this.costBearerAppointments.length;
    }
    return 0;
  }

  public onAbortPayment(): void {
    this.resetBalance();
    this.resetCostBearersPaymentForm();
    this.controlSum = "";
  }

  public mounted() {
    eventBus.$on("customer-payments-updated", (data: { studentsPayment: any; invoicesPayment: any; controlSum: any }) => {
      this.studentsPayment = data.studentsPayment;
      this.invoicesPayment = data.invoicesPayment;
      this.controlSum = data.controlSum;
    });

    this.setDefaultData();
  }

  private setDefaultData() {
    this.costBearersPaymentData.date = moment().format("YYYY-MM-DD");
    this.costBearersPaymentData.paymentMethod = PAYMENT_METHODS[1];
  }

  public async onSubmitPayment(): Promise<void> {
    this.$v.$touch();

    if (this.$v.$invalid) return;

    if (this.typeCreate()) {
      await this.createCostBearerPaymentAction({
        costBearerId: this.selectedCostBearerId,
        refund: this.costBearersPaymentData.refund,
        description: this.costBearersPaymentData.description,
        date: this.costBearersPaymentData.date,
        amountInEur: this.costBearersPaymentData.amountInEur,
        paymentMethod: this.costBearersPaymentData.paymentMethod,
        studentsPayment: this.studentsPayment,
        invoicesPayment: this.invoicesPayment,
      });

      if (this.createCostBearerPaymentSuccess) {
        this.$v.$reset();
        this.resetCostBearersPaymentForm();
        this.resetCostBearers();
      }
    }
  }

  public resetCostBearersPaymentForm(): void {
    this.costBearersPaymentData = {
      refund: false,
      description: "",
      date: moment().format("YYYY-MM-DD"),
      amountInEur: null,
      paymentMethod: PAYMENT_METHODS[1],
    };
  }

  public resetBalance(): void {
    this.$emit("reset");
  }

  public resetCostBearers(): void {
    this.$emit("reset-cost-bearers");
  }

  public resetBottomTab(): void {
    this.$emit("bottom", this.bottomTab);
  }

  @Validations()
  public validations(): Record<string, any> {
    return {
      costBearersPaymentData: {
        description: {},
        date: { required },
        amountInEur: {
          required,
          minValue: minValue(0),
        },
        paymentMethod: { required },
      },
    };
  }

  @Watch("balanceActiveTab", { immediate: true, deep: true })
  private onBalanceTabSelect(data: any): void {
    this.topCostBearerTab = data;
  }

  @Watch("balanceBottomTab", { immediate: true, deep: true })
  private onBalanceBottomChange(data: any): void {
    this.bottomTab = data;
  }

  protected async cancelInvoiceByPaymentDocument(paymentDocumentId: number): Promise<void> {
    await this.cancelInvoiceCostBearer(paymentDocumentId);

    if (this.cancelPaymentDocumentSuccess) {
      this.fetchCostBearerPaymentDocuments();
    }
  }

  protected async cancelInvoiceByCostBearerDocument(costBearerDocumentId: number): Promise<void> {
    await this.cancelInvoiceCostBearer(costBearerDocumentId);

    if (this.cancelPaymentDocumentSuccess) {
      this.fetchCostBearerPaymentDocuments();
    }
  }

  public fetchCostBearerPaymentDocuments() {
    (this.$refs["costBearerPaymentDocuments"] as CostBearerPaymentDocuments)?.onFilter();
  }

  private get costBearerInfoLoading() {
    return (
      this.costBearerLoading ||
      this.costBearerBalancesLoading ||
      this.costBearerAppointmentsLoading ||
      this.costBearerUnpaidInvoicesLoading ||
      this.studentEducationsByCostBearerLoading ||
      this.archiveCostBearerLoading
    );
  }
}
