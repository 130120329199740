import { Component, Vue } from "vue-property-decorator";
import { getFileWithBlob } from "@/utils/File";

@Component
export default class CostBearerPreviewDocumentMixin extends Vue {
  protected source: any = null;
  protected blob: any = null;
  protected mimeType: any = null;
  protected documentLoading = false;
  public fetchDocument(item: any): any {
    this.documentLoading = true;
    if (item.studentDocumentId) {
      return getFileWithBlob(
        {
          method: "get",
          url: `/student-documents/${item.studentDocumentId}`,
        },
        false
      )
        .then((blob: any) => {
          this.source = blob.url;
          this.blob = blob.blob;
          this.mimeType = blob.mimeType;
          return Promise.resolve({ success: true, blob });
        })
        .catch(() => {
          return Promise.resolve({ success: false });
        })
        .finally(() => {
          this.documentLoading = false;
        });
    } else if (item.costBearerDocumentId) {
      return getFileWithBlob(
        {
          method: "get",
          url: `/cost-bearer-documents/${item.costBearerDocumentId}`,
        },
        false
      )
        .then((blob: any) => {
          this.source = blob.url;
          this.blob = blob.blob;
          this.mimeType = blob.mimeType;
          // @ts-ignore
          return Promise.resolve({ success: true, blob });
        })
        .catch(() => {
          return Promise.resolve({ success: false });
        })
        .finally(() => {
          this.documentLoading = false;
        });
    }
  }
  public setBlobOptions() {
    const options = {
      blob: this.blob ? this.blob : null,
      mimeType: this.mimeType ? this.mimeType : null,
    };
    return options;
  }
}
