

















import { Component, Prop } from "vue-property-decorator";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import StudentRequestMixin from "@/mixins/Request/StudentRequestMixin";
import { mixins } from "vue-class-component";

@Component({
  components: {
    FscSimpleCard,
  },
})
export default class StudentArchive extends mixins(StudentRequestMixin) {
  public name = "StudentArchive";

  @Prop({ type: Boolean, default: () => false })
  public driveBuzzLoginAllowed!: boolean;

  @Prop({ type: Number })
  public studentId!: number;

  @Prop({ type: Boolean })
  public loading!: boolean;

  public get allowed() {
    return this.driveBuzzLoginAllowed;
  }

  private get isLoading() {
    return this.allowDriveBuzzLoginLoading || this.loading;
  }

  public onChangeDriveBuzzAllowed(allowed: any) {
    this.$emit("update:driveBuzzLoginAllowed", allowed);
    if (allowed !== null) {
      this.allowDriveBuzzLogin(this.studentId, allowed);
    }
  }
}
