
















































































import { Component, Prop, Vue } from "vue-property-decorator";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import { TranslateResult } from "vue-i18n";
import Datepicker from "@/components/Datepicker.vue";
import i18n from "@/i18n";
import { DRIVING_SCHOOL_APPOINTMENT } from "@/constants/Appointments";
import FscMultiselect from "@/components/FscMultiselect.vue";
import TimePicker from "@/components/TimePicker.vue";
import AbortButton from "@/components/Button/AbortButton.vue";

@Component({
  components: {
    FscSimpleCard,
    Datepicker,
    FscMultiselect,
    TimePicker,
    AbortButton,
  },
})
export default class DrivingAppointmentDetails extends Vue {
  public name = "DrivingAppointmentDetails";

  @Prop()
  public appointment!: any;

  public instructorInitials = "FSC";

  public appointmentTypeInput = {
    id: DRIVING_SCHOOL_APPOINTMENT,
    name: i18n.t("event_types.driving_appointment"),
  };

  public appointmentTypeInputOption = [
    {
      id: DRIVING_SCHOOL_APPOINTMENT,
      name: i18n.t("event_types.driving_appointment"),
    },
  ];

  protected get getLabel(): TranslateResult {
    return this.$t("event_types.driving_appointment") || "";
  }

  public onAbort(): void {
    this.$emit("abort");
  }
}
