






import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import AbortButton from "@/components/Button/AbortButton.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import PreviewForm from "@/components/PreviewForm.vue";

@Component({
  components: { PreviewForm, AbortButton, FscSimpleCard, SaveButton },
})
export default class EducationContractDocumentPreview extends Vue {
  public name = "EducationContractDocumentPreview";

  @Prop()
  public studentEducationId!: any;

  @Prop()
  public documentDate!: any;

  @Prop()
  public source!: any;

  @Prop()
  public isLoading!: boolean;

  protected ok(): void {
    this.$emit("ok");
  }

  protected cancel(): void {
    this.$emit("cancel");
  }
}
