





















import { Component, Prop, Vue } from "vue-property-decorator";
import { IStudentEducationBKFEducationDTO } from "@/interfaces/IStudentEducationBKFEducationDTO";

@Component
export default class StudentEducationBKFEducationRow extends Vue {
  public name = "StudentEducationBKFEducationRow";

  @Prop()
  public item!: IStudentEducationBKFEducationDTO;

  padMinutes(num: any) {
    return String(num).padStart(3, " ");
  }
}
