import { Component, Vue } from "vue-property-decorator";
import { AxiosError, AxiosResponse } from "axios";
import createCancelableRequest from "@/utils/axiosCancelable";
import { ISaldo } from "@/interfaces/Student/ISaldo";

const URL = "balance";

@Component
export default class SaldoRequestMixin extends Vue {
  private saldoCancelableRequest = createCancelableRequest(SaldoRequestMixin.name);

  public saldo: Array<ISaldo> = [];
  private saldoLoadingQueue = 0;
  public saldoSuccess = false;
  public saldoError: Record<any, any> | null = null;

  public get saldoLoading() {
    return this.saldoLoadingQueue > 0;
  }

  public async findSaldoByStudent(studentId: number): Promise<void> {
    this.saldoLoadingQueue++;
    this.saldoSuccess = false;
    this.saldoError = null;
    return await this.saldoCancelableRequest
      .makeRequest({ url: `${URL}/${studentId}`, method: "get" })
      .then((response: AxiosResponse) => {
        this.saldo = response.data;
      })
      .catch((error: AxiosError) => {
        this.saldoError = error.response?.data;
      })
      .finally(() => {
        this.saldoLoadingQueue--;
      });
  }

  public destroyed() {
    this.saldoCancelableRequest.cancelPreviousRequest();
  }
}
