import { Component, Vue } from "vue-property-decorator";
import axios from "@/utils/axios";
import { AxiosError, AxiosResponse } from "axios";

@Component
export default class ProofOfTrainingRequestMixin extends Vue {
  public createProofOfTrainingLoading = false;
  public createProofOfTrainingSuccess = false;
  public createProofOfTrainingError = null;

  public async createProofOfTraining(id: number, date: string) {
    this.createProofOfTrainingLoading = true;
    this.createProofOfTrainingSuccess = false;
    this.createProofOfTrainingError = null;

    return await axios({ url: `proof-of-training?studentEducationId=${id}&issueDate=${date}`, method: "post" })
      .then((response: AxiosResponse) => {
        this.createProofOfTraining = response.data;
        this.createProofOfTrainingSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.createProofOfTrainingError = error.response?.data;
      })
      .finally(() => {
        this.createProofOfTrainingLoading = false;
      });
  }
}
