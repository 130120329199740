import i18n from "@/i18n";

export const NOT_REPEAT = "not_repeat";
export const DAILY = "daily";
export const DAILY_ON_WEEKDAYS = "workdays";
export const WEEKLY = "weekly";
export const MONTHLY = "monthly";
export const YEARLY = "yearly";

export const RECURRING_APPOINTMENTS: Array<any> = [
  {
    value: NOT_REPEAT,
    name: i18n.t("general.not_repeat").toString(),
  },
  {
    value: DAILY,
    name: i18n.t("general.daily").toString(),
  },
  {
    value: DAILY_ON_WEEKDAYS,
    name: i18n.t("general.daily_on_weekdays").toString(),
  },
  {
    value: WEEKLY,
    name: i18n.t("general.weekly").toString(),
  },
  {
    value: MONTHLY,
    name: i18n.t("general.monthly").toString(),
  },
  {
    value: YEARLY,
    name: i18n.t("general.yearly").toString(),
  },
];

export const ONLY_THIS = "only_this";
export const THIS_AND_FOLLOWING = "this_and_following";
export const ALL = "all";

export const RECURRING_APPOINTMENTS_OPTIONS = [
  { text: i18n.t("general.only_this"), value: ONLY_THIS },
  {
    text: i18n.t("general.this_and_following"),
    value: THIS_AND_FOLLOWING,
  },
  { text: i18n.t("general.all"), value: ALL },
];
