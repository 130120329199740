


















import { Component, Prop, Vue } from "vue-property-decorator";
import FscModal from "@/components/Modal/FscModal.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import { RECURRING_APPOINTMENTS_OPTIONS } from "@/constants/RecurringAppointments";
import i18n from "@/i18n";
import AbortButton from "@/components/Button/AbortButton.vue";

@Component({
  components: { AbortButton, SaveButton, FscModal },
})
export default class RecurringModal extends Vue {
  public name = "RecurringModal";

  @Prop({ type: String })
  public modalId!: string;

  @Prop({ type: String, default: () => i18n.t("general.serial_appointments_delete_header") })
  public modalTitle!: string;

  public selected = null;
  public options = RECURRING_APPOINTMENTS_OPTIONS;

  protected okHandle(ok: () => void): void {
    if (this.selected) {
      this.$emit("ok", this.selected);
      ok();
    }
  }

  protected cancelHandle(cancel: () => void): void {
    this.$emit("cancel");
    this.selected = null;
    cancel();
  }
}
