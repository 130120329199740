




import { Component, Vue } from "vue-property-decorator";
import ActionButton from "@/components/Button/ActionButton.vue";
@Component({
  components: { ActionButton },
})
export default class PauseButton extends Vue {
  public name = "PauseButton";
}
