
















import { Vue, Component, Prop } from "vue-property-decorator";
import SaveButton from "@/components/Button/SaveButton.vue";
import AbortButton from "@/components/Button/AbortButton.vue";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";

@Component({
  components: { FscSimpleCard, AbortButton, SaveButton },
})
export default class PreviewForm extends Vue {
  @Prop({ default: false })
  public isLoading!: boolean;

  @Prop({ default: "", required: true })
  public source!: any;

  public cancel() {
    this.$emit("cancel");
  }

  public ok() {
    this.$emit("ok");
  }
}
