export enum OverlappingCheckEnum {
  YES,
  NO,
}

export function boolenToEnum(value: false | true): OverlappingCheckEnum {
  switch (value) {
    case false:
      return OverlappingCheckEnum.YES;
    default:
      return OverlappingCheckEnum.NO;
  }
}
