
























































































































import { Component, InjectReactive, Prop, Watch } from "vue-property-decorator";
import AbortButton from "@/components/Button/AbortButton.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import EditButton from "@/components/Button/EditButton.vue";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import FscHorizontalTable from "@/components/Table/FscHorizontalTable.vue";
import CreateButton from "@/components/Button/CreateButton.vue";
import RemoveButton from "@/components/Button/RemoveButton.vue";
import FscModal from "@/components/Modal/FscModal.vue";
import { PropType } from "vue";
import { IStudentEducation } from "@/interfaces/Student/IStudentEducation";
import CheckListRequestMixin from "@/mixins/Request/CheckListRequestMixin";
import { mixins } from "vue-class-component";
import CheckListTemplateRequestMixin from "@/mixins/Request/CheckListTemplateRequestMixin";
import RegistrationInfoRequestMixin from "@/mixins/Request/RegistrationInfoRequestMixin";
import { IChecklistTemplate } from "@/interfaces/Student/IChecklistTemplate";
import RegistrationInfoFormModal from "@/views/Student/Info/RegistrationInfoFormModal.vue";
import { IChecklistItem } from "@/interfaces/Student/IChecklist";
import { IStudent } from "@/interfaces/Student/IStudent";

@Component({
  components: {
    RegistrationInfoFormModal,
    RemoveButton,
    CreateButton,
    FscHorizontalTable,
    FscSimpleCard,
    EditButton,
    AbortButton,
    SaveButton,
    FscModal,
  },
})
export default class StudentChecks extends mixins(CheckListRequestMixin, CheckListTemplateRequestMixin, RegistrationInfoRequestMixin) {
  public name = "StudentChecks";

  @Prop({ type: Boolean, default: () => false })
  public loading: any;

  @Prop({ type: Boolean, default: () => false })
  public educationLoading!: boolean;

  @Prop({ type: Object as PropType<IStudent> })
  public student!: IStudent;

  @Prop({ type: Array as PropType<Array<IStudentEducation>> })
  public studentEducations!: Array<IStudentEducation>;

  @InjectReactive("isDisabled")
  public disabled!: boolean;

  private registrationInfoColumns = [
    {
      label: this.$t("user.application_sub"),
      key: "registrationDate",
    },
    {
      label: this.$tc("general.return_number", 1),
      key: "returnNumber",
    },
    {
      label: this.$t("user.application_valid"),
      key: "approvalDate",
    },
  ];

  public registrationInfoModalId = "registration-info-modal";

  private registrationInfoTab = 0;
  private activeCheckListTab = 0;

  public selectedChecklistTemplate: IChecklistTemplate | null = null;

  private get studentId() {
    return this.student?.id;
  }

  public get localCheckLists() {
    return this.checkListByStudentByStudent;
  }

  public get hasSelectedChecklistTemplate() {
    return this.selectedChecklistTemplate?.id;
  }

  public fetchChecklistAndChecklistTemplates(studentId: number): void {
    this.findCheckListsByStudent(studentId);
    this.findAllCheckListTemplates();
  }

  public onCreateCheckList(): void {
    this.$bvModal.show("addCheckListTemplatePopover");
  }

  public async onDeleteCheckList(): Promise<void> {
    if (this.disabled) return;
    const templateId = this.localCheckLists?.[this.activeCheckListTab]?.checklistTemplateId;
    const studentId = this.studentId;
    if (templateId && studentId) {
      await this.removeCheckListTemplateByStudent(studentId, templateId);

      if (this.removeCheckListTemplateSuccess) {
        await this.fetchChecklistAndChecklistTemplates(this.studentId);
      }
    }
  }

  public async onSubmitTemplateItem(): Promise<void> {
    if (this.disabled || !this.hasSelectedChecklistTemplate) return;
    if (this.selectedChecklistTemplate?.id && this.studentId) {
      await this.createCheckListByStudent(this.studentId, this.selectedChecklistTemplate.id);
      await this.fetchChecklistAndChecklistTemplates(this.studentId);
      this.selectedChecklistTemplate = null;
      this.$bvModal.hide("addCheckListTemplatePopover");
    }
  }

  public onAbortTemplateAdd() {
    this.selectedChecklistTemplate = null;
    this.$bvModal.hide("addCheckListTemplatePopover");
  }

  // Toggle on/off
  public onCheckBoxCheckListItemChange(item: IChecklistItem) {
    item.checked = !item.checked;
    this.updateCheckLists(item.id, item.checked);
  }

  public onAbortTemplateDelete(): void {
    this.$bvModal.hide("deleteCheckListTemplatePopover");
  }

  public async onConfirmTemplateDelete(): Promise<void> {
    await this.onDeleteCheckList();
    this.$bvModal.hide("deleteCheckListTemplatePopover");
  }

  public onDeleteCheckListModalPopUp(): void {
    this.$bvModal.show("deleteCheckListTemplatePopover");
  }

  public get checklistItemsEnabled() {
    return this.localCheckLists?.length;
  }

  @Watch("studentId", { immediate: true })
  public onStudentIdChange(studentId: number): void {
    if (studentId) {
      this.fetchChecklistAndChecklistTemplates(studentId);
    }
  }

  @Watch("studentEducations", { immediate: true, deep: true })
  public onStudentEducationsChange(studentEducations: Array<IStudentEducation>): void {
    if (studentEducations.length) {
      const selectedEducation = studentEducations[this.registrationInfoTab];
      if (selectedEducation) {
        this.fetchRegistrationInfo(selectedEducation.id);
      }
    }
  }

  private onChangeRegistrationInfoTab(tabIndex: number): void {
    const selectedEducation = this.studentEducations?.[tabIndex];
    if (selectedEducation) {
      this.fetchRegistrationInfo(selectedEducation.id);
    }
  }

  private async fetchRegistrationInfo(studentEducationId: number): Promise<void> {
    await this.findRegistrationInfoByStudentEducation(studentEducationId);
  }

  protected onClickEdit() {
    if (this.disabled) return;
    this.$bvModal.show(this.registrationInfoModalId);
  }

  private get checklistItemsByActiveTab() {
    return this.localCheckLists[this.activeCheckListTab]?.checklistItems || [];
  }

  protected async registrationInfoOk(): Promise<void> {
    await this.updateRegistrationInfo(this.registrationInfoStudentEducation);

    const selectedEducation = this.studentEducations?.[this.registrationInfoTab];

    if (this.updateRegistrationInfoSuccess && selectedEducation?.id) {
      this.fetchRegistrationInfo(selectedEducation.id);
    }
  }

  private get registrationInfoIsLoading() {
    return (
      this.registrationInfoStudentEducationLoading ||
      this.updateRegistrationInfoLoading ||
      this.createCheckListLoading ||
      this.removeCheckListTemplateLoading ||
      this.checkListByStudentLoading ||
      this.loading
    );
  }

  private get checkListIsLoading() {
    return this.loading || this.updateCheckListLoading || this.checkListTemplatesLoading;
  }
}
