


























































import { Component, Prop, Vue } from "vue-property-decorator";
// @ts-ignore
import VueSignaturePad from "vue-signature-pad";

@Component
export default class SignTrainingContract extends Vue {
  public name = "SignTrainingContract";

  @Prop({ type: Boolean, default: () => false })
  private isAdult!: boolean;

  @Prop({ type: String })
  private addonsCheckboxText!: string | null;

  @Prop({ type: String })
  private gdprUrlCheckboxText!: string | null;

  @Prop()
  private typeSignature!: any;

  private termsAndConditions = false;
  private confirmChargeableAddons = false;
  private confirmGDPRs = false;
  private padW = "44vw";
  private padH = "22vw";

  public getData(): any {
    const studentSignature = (this.$refs.studentSignature as VueSignaturePad).saveSignature();
    const parentSignature = (this.$refs.parentSignature as VueSignaturePad)?.saveSignature();

    return {
      studentIsEmpty: studentSignature.isEmpty,
      studentSignFile: studentSignature.data,
      parentIsEmpty: Boolean(parentSignature?.isEmpty),
      parentSignFile: parentSignature?.data,
      termsAndConditions: this.termsAndConditions,
      confirmChargeableAddons: this.confirmChargeableAddons,
      confirmGDPRs: this.confirmGDPRs,
      typeSignature: this.typeSignature,
    };
  }
}
