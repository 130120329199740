import { Component, Vue } from "vue-property-decorator";
import { AxiosError, AxiosResponse } from "axios";
import createCancelableRequest from "@/utils/axiosCancelable";
import { IAppointment } from "@/interfaces/Student/IAppointment";

const URL = "appointments/future-appointments-count";

@Component
export default class AppointmentsCountRequestMixin extends Vue {
  private appointmentCountCancelableRequest = createCancelableRequest(AppointmentsCountRequestMixin.name);

  public appointmentsCountByStudent: Array<IAppointment> = [];
  private appointmentsCountByStudentLoadingQueue = 0;
  public appointmentsCountByStudentSuccess = false;
  public appointmentsCountByStudentError: Record<any, any> | null = null;
  public get appointmentsCountByStudentLoading() {
    return this.appointmentsCountByStudentLoadingQueue > 0;
  }

  public async findAppointmentsCountByStudent(studentId: number): Promise<void> {
    this.appointmentsCountByStudentLoadingQueue++;
    this.appointmentsCountByStudentSuccess = false;
    this.appointmentsCountByStudentError = null;
    return await this.appointmentCountCancelableRequest
      .makeRequest({ url: `${URL}/${studentId}`, method: "get" })
      .then((response: AxiosResponse) => {
        this.appointmentsCountByStudent = response.data;
        this.appointmentsCountByStudentSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.appointmentsCountByStudentError = error.response?.data;
      })
      .finally(() => {
        this.appointmentsCountByStudentLoadingQueue--;
      });
  }

  public destroyed() {
    this.appointmentCountCancelableRequest.cancelPreviousRequest();
  }
}
