





























































































import { Component, Prop } from "vue-property-decorator";
import moment from "moment";
import Datepicker from "@/components/Datepicker.vue";
import AbortButton from "@/components/Button/AbortButton.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import Validation from "@/components/Validation.vue";
import FscInputDecimal from "@/components/Field/FscInputDecimal.vue";
import { PAYMENT_METHODS } from "@/constants/PaymentMethods";
import CostBearerPaymentsByStudentEducation from "@/views/CostBearers/Info/CostBearerPaymentsByStudentEducation.vue";
import CostBearerPaymentsByUnpaidInvoices from "@/views/CostBearers/Info/CostBearerPaymentsByUnpaidInvoices.vue";
import VatRequestMixin from "@/mixins/Request/VatRequestMixin";
import { mixins } from "vue-class-component";

@Component({
  components: {
    CostBearerPaymentsByUnpaidInvoices,
    Datepicker,
    AbortButton,
    SaveButton,
    Validation,
    FscInputDecimal,
    CostBearerPaymentsByStudentEducation,
  },
})
export default class CostBearersBalanceTop extends mixins(VatRequestMixin) {
  public name = "CostBearersBalanceTop";

  @Prop()
  public allEducationsByCostBearerId: any;

  @Prop()
  public costBearersPaymentData!: any;

  @Prop()
  public v!: any;

  @Prop()
  public unpaidInvoices!: any;

  @Prop()
  public controlSum!: any;

  @Prop({ type: Boolean, required: false, default: () => false })
  public putLoading!: boolean;

  public get customersWithEducations() {
    if (!this.allEducationsByCostBearerId) return [];
    return this.allEducationsByCostBearerId
      .filter((education: any) => education.balance.saldo < 0)
      .map((education: any) => ({
        id: education.id,
        name: education.studentFirstName + " " + education.studentLastName + " " + education.mainLicenseClass,
        amount: Math.abs(education.balance.toPay ? education.balance.toPay : 0),
        toPay: Math.abs(education.balance.toPay ? education.balance.toPay : 0),
        invoices:
          education?.invoices && education.invoices.length > 0
            ? education.invoices
                .filter((invoice: any) => !invoice.paid)
                .map((invoice: any) => ({
                  name: `${invoice.invoiceNumber} | ${moment(invoice.createdOn).format("DD.MM.yyyy")} | ${invoice.amountInEur} €`,
                  amountInEur: Math.abs(invoice.amountInEur),
                  id: invoice.id,
                  paid: invoice.paid,
                }))
            : [],
      }));
  }

  public get initialDate(): any {
    return moment().format("YYYY-MM-DD");
  }

  public get paymentMethodOptions(): Array<any> {
    return PAYMENT_METHODS;
  }

  public onAbort(): void {
    this.$emit("on-abort");
  }

  public onSubmit(): void {
    this.$emit("on-submit");
  }

  public mounted() {
    this.findAllVats({ archived: false });
  }
}
