
import { Component, Prop } from "vue-property-decorator";
import { PropType } from "vue";
import SaldoMixin from "@/mixins/SaldoMixin";
import { mixins } from "vue-class-component";
import { ISaldo } from "@/interfaces/Student/ISaldo";

@Component({})
export default class StudentBalanceCard extends mixins(SaldoMixin) {
  public name = "StudentBalanceCard";

  @Prop({ type: Object as PropType<ISaldo> })
  public totalSaldo!: ISaldo;

  @Prop({ type: Boolean, default: () => false })
  public isLoading!: boolean;

  private get saldoClass() {
    if (this.totalSaldo.actualBalance < 0) {
      return "text-danger";
    }
    return "text-success";
  }
}
